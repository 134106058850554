<template>
  <section class="invention-navigation">
    <header class="invention-navigation-header">
      <div>
        <hub-button variant="icon" color="primary" @click="createVisible = true">
          <hub-icon name="text-box-plus"></hub-icon>
        </hub-button>
        <hub-create-invention v-if="createVisible" @created="created" @close="createVisible = false"></hub-create-invention>
      </div>
      <hub-button type="icon" class="expand-button" @click="$emit('changeView')">
        <hub-icon :name="viewMode === 'detailed' ? 'chevron-left' : 'chevron-right'" />
      </hub-button>
    </header>
    <div class="navigation-wrp" style="overflow-y: scroll">
      <div class="accordion-item">
        <div class="header" @click="myExpanded = !myExpanded">
          <hub-button variant="icon">
            <hub-icon v-if="!myExpanded" name="chevron-up" />
            <hub-icon v-else name="chevron-down" />
          </hub-button>
          <div class="title">
            <div>
              <span> Recently visited</span>
              <span v-if="my?.data?.length" class="count">({{ my?.data?.length || 0 }})</span>
            </div>
          </div>
        </div>
        <div v-if="!myExpanded" class="invention-navigation-list">
          <ul v-if="!isGetMyCollectionRequestPending">
            <li v-for="item of my.data" :key="item.id" class="invention-navigation-list-item">
              <router-link :to="`/inventions/${item.id}`">
                <hub-reference-list :invention="{ id: item.id, references: item.references }" :show-navigation="false" />
              </router-link>
              <hub-button variant="icon" class="remove" @click.stop="removeFromList(item)"><hub-icon name="close" size="sm"></hub-icon></hub-button>
            </li>
          </ul>
          <div v-else class="loading-wrapper">
            <hub-icon name="loading" spin size="lg" style="justify-self: center; align-self: center"></hub-icon>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div>
          <div class="header" @click="myExpanded = !myExpanded">
            <hub-button variant="icon">
              <hub-icon v-if="myExpanded" name="chevron-up" />
              <hub-icon v-else name="chevron-down" />
            </hub-button>
            <div class="title">
              <div style="display: flex">
                <div>My active inventions</div>
                <div v-if="total" class="count">({{ total || 0 }})</div>
              </div>
              <hub-multiselect
                v-if="viewMode === 'detailed' && myExpanded"
                class="sort-change-select"
                style="padding-left: 10px"
                :value="sort"
                :can-deselect="false"
                :can-clear="false"
                :options="[
                  { label: 'Next Task Due Date', value: 'next' },
                  { label: 'Critical Dates', value: 'critical' }
                ]"
                @click.stop=""
                @change="onSortChange"
              />
            </div>
          </div>
          <div v-if="myExpanded" class="invention-navigation-list">
            <transition-group v-if="!isGetInventionsRequestPending" name="flip-list" tag="ul">
              <li v-for="item of collection" :key="item.id" class="invention-navigation-list-item">
                <router-link :to="`/inventions/${item.id}`">
                  <div v-if="viewMode === 'composite'" class="composite">
                    <hub-reference-list :invention="{ id: item.id, references: item.references }" :show-navigation="false" />
                  </div>
                  <div v-else class="detailed">
                    <div class="first-row">
                      <hub-reference-list :invention="{ id: item.id, references: item.references }" :show-navigation="false" />
                      <!-- <div style="display: flex">
                        <router-link :to="`/inventions/${item.id}/ifw`">
                          <hub-icon class="notification-icon" name="file-outline" size="md" title="New files" />
                        </router-link>
                        <router-link :to="`/inventions/${item.id}/messages`">
                          <hub-icon class="notification-icon" name="message-outline" size="md" title="New messages" />
                        </router-link>
                        <router-link :to="`/inventions/${item.id}/emails`">
                          <hub-icon class="notification-icon" name="email-outline" size="md" title="New emails" />
                        </router-link>
                      </div> -->
                    </div>
                    <div class="second-row">
                      <div class="milestone">
                        <div class="title">
                          {{ item.title }}
                        </div>
                      </div>
                      <div class="task">
                        <div>
                          <hub-icon
                            v-if="taskIsCriticalTask(item) && warningIcon(item)?.length && item.nextTaskTitle !== 'No active tasks'"
                            class="warning-icon"
                            :name="warningIcon(item)"
                            size="sm"
                            :title="`This task is critical for '${item.title}' milestone`"
                          />
                        </div>
                        <div class="title" :title="item.nextTask?.title">{{ item.nextTaskTitle }}</div>
                        <div class="notes trim">
                          <styled-content :flat="true" v-html="item.nextTask?.notes"></styled-content>
                        </div>
                        <hub-locale-due-at
                          class="due-at"
                          :due-at="item.nextTask.dueAt"
                          :color-coding-enabled="true"
                          :emtpy-due-at-text="item.nextCriticalTask?.dueAt ? 'no due at' : ''"
                        />
                      </div>
                      <div v-if="!taskIsCriticalTask(item)" class="critical-task">
                        <div></div>
                        <div class="title">
                          {{ item.nextCriticalTask?.title }}
                        </div>
                        <div class="notes trim">
                          <styled-content :flat="true" v-html="item.nextCriticalTask?.notes"></styled-content>
                        </div>
                        <hub-locale-due-at class="due-at" :due-at="item.nextCriticalTask?.dueAt" :color-coding-enabled="true" />
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
              <hub-observe v-if="canLoadMore" :get-root-ref="() => $refs['streamRootRef']" @intersect="more"> </hub-observe>
            </transition-group>
            <div v-else class="loading-wrapper">
              <hub-icon name="loading" spin size="lg" style="justify-self: center; align-self: center"></hub-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import { differenceInHours, endOfDay } from 'date-fns';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import StyledContent from '@/components/common/editor/StyledContent';
import Create from '../Create';
import LocaleDueAt from '@/components/reports/components/LocaleDueAt.vue';
import ReferenceList from '@/components/common/ReferenceList.vue';
import Multiselect from '@/components/common/Multiselect.vue';
import Observe from '@/components/inventions/Observer';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-reference-list': ReferenceList,
    'hub-create-invention': Create,
    'hub-multiselect': Multiselect,
    StyledContent,
    'hub-locale-due-at': LocaleDueAt,
    'hub-observe': Observe
  },
  props: {
    viewMode: {
      type: String,
      default: 'composite'
    },
    sort: {
      type: String,
      default: 'next'
    },
    canLoadMore: {
      type: Boolean
    }
  },
  emits: ['sortingChanged', 'changeView', 'loadMore'],
  data() {
    return {
      createVisible: false,
      myExpanded: true
    };
  },
  computed: {
    ...mapState({
      my: s => s.inventions.my,
      collection: s => s.reports.myInventions.data,
      total: s => s.reports.myInventions.total,
      isGetMyCollectionRequestPending: s => s.inventions.isGetMyCollectionRequestPending,
      isGetInventionsRequestPending: s => s.reports.myInventions.isRequestPending
    })
  },
  methods: {
    async removeFromList(item) {
      await this.$store.dispatch('inventions/remove', item);
      if (item.id === this.$route.params.id) {
        this.$router.push({ path: `/inventions` });
      }
    },
    async created(item) {
      await this.$store.dispatch('inventions/add', item);
      this.$router.push({ path: `/inventions/${item.id}` });
      this.createVisible = false;
    },
    taskIsCriticalTask(item) {
      return item.nextTask?.id === item.nextCriticalTask?.id;
    },
    warningIcon(item) {
      const difference = differenceInHours(endOfDay(new Date(item.dueAt)), new Date());
      if (difference <= 24) {
        return 'fire';
      }

      if (difference <= 96 && difference > 24) {
        return 'alert-circle-outline';
      }

      return '';
    },
    async onSortChange(sort) {
      this.$emit('sortingChanged', sort);
    },
    more() {
      this.$emit('loadMore');
    }
  }
};
</script>

<style lang="scss" scoped>
.invention-navigation {
  background-color: var(--theme-surface);
  height: 100%;

  .invention-navigation-header {
    display: flex;
    justify-content: space-between;
  }

  > header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    button {
      margin: 0 0.25rem;
    }
  }
  .navigation-wrp {
    height: calc(100% - 50px);
    overflow-y: scroll;
    margin-left: 5px;
  }

  .accordion-item {
    background: var(--theme-background);
    margin-bottom: 0.25rem;
    border-radius: 2px;
    cursor: pointer;
    .header {
      width: 100%;
      display: grid;
      padding: 10px;
      grid-template-columns: 50px 1fr;
      align-items: center;
      font-weight: 450;
      color: var(--theme-on-default);

      .title {
        font-size: 1rem;
        user-select: none;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 25px;
        align-items: center;

        .count {
          margin-left: 0.25rem;
          font-size: 0.9rem;
        }
      }
    }
    .loading-wrapper {
      text-align: center;
      margin: 1rem;
    }
  }
}

.invention-navigation-list {
  margin: 0.25rem;
  margin-bottom: 0;
  padding-bottom: 1px;
  user-select: none;
}

.invention-navigation-list-item {
  background: var(--theme-surface);
  margin-bottom: 0.25rem;
  transition: 0.5s all;
  position: relative;
  border-radius: 2px;
  a {
    display: block;
    padding: 0.75rem 0.75rem;
    color: var(--theme-on-surface);
    &.router-link-active {
      background-color: var(--theme-highlight);
    }
  }

  &:hover {
    background-color: var(--theme-highlight);
    cursor: pointer;
    .remove {
      display: block;
    }
  }

  .remove {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .composite {
    div {
      font-size: 0.8rem;
    }
  }

  .detailed {
    display: grid;
    grid-template-rows: max-content auto;

    .first-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        padding: 0;
        &.router-link-active {
          background-color: transparent;
        }

        .notification-icon {
          margin-left: 5px;
          color: var(--theme-on-surface);
        }
      }
    }

    .second-row {
      display: grid;
      grid-template-rows: auto;
      align-items: start;
      grid-gap: 2px;

      .milestone {
        display: flex;
        font-weight: 600;

        .title {
          font-size: 0.9rem;
        }
      }

      .task {
        display: grid;
        grid-template-columns: auto 1fr 1fr 75px;

        .warning-icon {
          color: var(--theme-warning);
        }

        .title {
          font-size: 0.8rem;
          color: var(--theme-primary);
        }

        .due-at {
          justify-self: self-end;
        }
      }

      .critical-task {
        display: grid;
        grid-template-columns: auto 1fr 1fr 75px;

        .title {
          font-size: 0.7rem;
          opacity: 0.8;
        }

        .due-at {
          justify-self: self-end;
        }
      }

      .trim {
        margin-right: 0.25rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  div {
    font-size: 0.8rem;
  }
}

.flip-list-move {
  transition: transform 0.7s;
  z-index: 100;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 0.7s ease;
}
.flip-list-enter-from,
.flip-list-leave-to {
  opacity: 0.4;
  transform: translateX(50%);
}
</style>
